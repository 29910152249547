import React from "react";
import Styles from "./styles.module.css";
// import Themes from "./theme/themes";
import { Link } from "react-router-dom";
import ConferenceFeesTable from "./tables/conferencefeestable";
import { Download,  } from "@mui/icons-material";
import Theme1 from "./theme/theme1.js";
import Payment from "./theme/payment.js";
import { Helmet } from "react-helmet-async";
import pageImage from "./images/conference.jpg";
import SocialShare from "../hooks/sharepage/SocialShare.js";

export default function Conference2024() {
  const pageTitle =
    "AGREC- CONFERENCE ON GOVERNANCE AND DEVELOPMENT IN KENYA 2024";

  const pageDescription =
    "Consolidating proven knowledge for effective governance, development, growth and prosperity in Kenya.";
  const pageUrl = "/media/Conference 2024";

  return (
    <>
      {" "}
      <Helmet>
        <meta name="title" content={pageTitle} />
        <meta name="description" content={pageDescription} />
        <meta
          name="keywords"
          content="Conference Kenya, Nairobi, Governance, Development, Kenya School of Government, Civil Society, Capacity Building"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="30 days" />
        <link rel="canonical" href={pageUrl} />
      </Helmet>
      <div className={Styles.container2}>
        <Link to="/conference registration">
          <img
            src="/images/conference.jpg"
            alt="Inaugural International Multi-Sectoral Conference on Governance and Development in Kenya 2024"
          />
        </Link>

        <div className={Styles.content}>
          {/* <h1>Overview</h1>
        <span>Conference Theme</span>
        <p>{ConferenceAbstract.paragraph1}</p>
        <span>Purpose</span>
        <p>{ConferenceAbstract.paragraph2}</p>
        <span>Background</span>
        <p>{ConferenceAbstract.paragraph3}</p>
        {Background.map((item, index) => (
          <li key={index}>{item.name}</li>
        ))}
        <h3>{ConferenceAbstract.conferencetheme}</h3> */}

          <h1>
            2024 Kenya International Conference on Governance and Development
          </h1>
          <span>Key Areas of Focus:</span>
          <Theme1 />
          {/* <Link to="/conference registration">
          <img
            src="/images/callforpapers.jpg"
            alt="Inaugural International Multi-Sectoral Conference on Governance for Development in Kenya 2024"
            style={{ width: "100%", margin: "0rem", padding: "0rem" }}
          />
        </Link> */}
          {/* <div>
          <span>Main Objective:</span>
          <p>
            The overall objective of the conference will be to enhance good
            governance for national development and prosperity.
          </p>
          <span>Main Objective:</span>
          <hr />
          {Objectives.map((item, index) => (
            <li key={index}>{item.name}</li>
          ))}
          <span>Expected Outputs</span>
          {ExpectedOutput.map((item, index) => (
            <li key={index}>{item.name}</li>
          ))}
        </div> */}
          {/* <Link to="/conference registration">
          <img
            src="/images/conference1.jpg"
            alt="Inaugural International Multi-Sectoral Conference on Governance and Development in Kenya 2024"
            style={{ width: "100%", margin: "0rem", padding: "0rem" }}
          />
        </Link> */}
          <span>Conference Registration Fees</span>
          <ConferenceFeesTable />
          <span>
            {" "}
            N/B Conference Registration Fee will cover: Tea, Lunch and
            Conference Package . Accomodation will be covered by the
            participants.
          </span>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div style={{ margin: "0 Auto" }}>
              <Payment />
            </div>
          </div>

          <div className={Styles.download}>
            <p style={{ fontWeight: "bold" }}>{note.note.substring(0, 60)}</p>
            <Link to={`/${note.note}`} target="_blank">
              <button className={Styles.button}>
                Download <Download />
              </button>
            </Link>
            
          </div>
          <div style={{ float: "right", padding:"1rem" }}>
            <SocialShare
              title={pageTitle}
              description={pageDescription}
              imageUrl={pageImage}
            />
          </div>
          {/* <div>
          <Link to="/conference registration">
            <img
              src="/images/conference2.jpg"
              alt="Inaugural International Multi-Sectoral Conference on Governance and Development in Kenya 2024"
              style={{ width: "100%", margin: "0rem", padding: "0rem" }}
            />
          </Link>
        </div> */}
         
        </div>
      </div>
    </>
  );
}

const note = {
  note: "AGREC Governance & Development Conference 2024  Concept Note.pdf",
};

const ConferenceAbstract = {
  paragraph1:
    "Consolidating proven knowledge for effective governance, development, growth and prosperity in Kenya.",
  paragraph2:
    "To provide a participatory and interactive platform to share experiences, lessons and transformative ideas for the country’s   growth and development",
  paragraph3:
    "Good governance is essential for Africa's economic progress, according to the African Development Bank. It is underpinned by effective states, engaged civil societies, and a vibrant private sector. However, Kenya faces several governance and development challenges, including:",
  paragraph4:
    "The objective of the Kigali High-Level Health Financing Conference is to bring together the high-level political leadership on the continent and mobilize partner support to:",
};

const Background = [
  {
    name: "a)	Transparency and accountability: There is a need for greater transparency in government decision-making and spending. ",
  },
  {
    name: "b)	Climate change: Climate change is a major threat to Kenya's economy and environment.  ",
  },
  {
    name: "c)	Weak civil society: Civil society plays a crucial role in holding government accountable and promoting good governance.  ",
  },
  {
    name: "d)	Ineffective implementation of laws and regulations: Laws and regulations are often not effectively implemented in Kenya.  ",
  },
  {
    name: "e)	Lack of strong institutions of governance: Kenya needs to develop strong institutions of governance, such as an independent judiciary and a professional civil service. ",
  },
  {
    name: "f)	Vulnerability of the economy to internal and external shocks: Kenya's economy is vulnerable to shocks such as droughts, floods, and global economic downturns.  ",
  },
];

const Objectives = [
  { name: "a)	Sharing and exchanging experiences, lessons and ideas." },
  {
    name: "b)	 Building a knowledge base/repository of innovations and best practices.",
  },
  {
    name: "c)	 Establishing a knowledge network of practitioners, researchers, academics. ",
  },
  { name: "d)	Contribute towards good governance practices." },
  {
    name: "e)	Elevate good governance including gender responsive governance and its practice in an evolving democracy",
  },
  {
    name: "f)	Launch and institutionalize (AGREC as) a knowledge platform on governance for development.",
  },
];
const ExpectedOutput = [
  { name: "a)	Action areas and resolutions" },
  { name: "b)	Conference Report " },
  { name: "c)	AGREC Journal Publication (addition of (1) and (2)" },
  { name: "d)	Sustained (ethics and) good governance consciousness mindset" },
];
