import React from "react";
import { Routes, Route } from "react-router-dom";
import Nomatch from "./nomatch";
import Home from "./home";
import Layout2 from "./components/layout/Layout2";
import Layout from "./components/layout/Layout";
import { Layout3 } from "./components/layout";
import {
  CivilSocietyOrganisations,
  GovernmentandPrivateSecor,
  PoliticalPartiesLiason,
  ResearchCommunication,
  SpecialTrainings,
} from "./components/programs";
import {
  BoardMembersEditorialTeam,
  OurStory,
  OurTeam,
  OurValues,
  StrategicPlan,
  WhereWeWork,
} from "./components/aboutus";
import {
  JournalPapers,
  Methodologies,
  ReserachCalls,
} from "./components/journal";
import {
  Blog,
  Conference2024,
  ConferenceDetail,
  ConferenceRegistration,
  Gallery,
  GalleryDetail,
  PressReleases,
  SubTheme,
  ThankYou,
} from "./components/media";
import {
  Contact,
  Feedback,
  PrivacyCookies,
  TermsConditions,
  ThankYouContact,
} from "./components/generalpages";
import { Downloads, GeneralReports, ToolKits } from "./components/resources";
import Projects from "./components/projects/projects";
import { HomeMiniOutlined } from "@mui/icons-material";

function AppRouter() {
  return (
    <>
      <Routes>
        <>
          {/* Layout1 */}
          <Route path="/" element={<Layout />}>
            <Route index element={<Home/>} />
            <Route
              path="programs/Civil Society Organisations"
              element={<CivilSocietyOrganisations />}
            />
             <Route
              path="home"
              element={<Home/>}
            ></Route>
            <Route
              path="programs/Government & Private Sector"
              element={<GovernmentandPrivateSecor />}
            ></Route>
            <Route
              path="programs/Research & Communication"
              element={<ResearchCommunication />}
            ></Route>
            <Route
              path="programs/speacial trainings & services"
              element={<SpecialTrainings />}
            ></Route>
            <Route path="contact" element={<Contact />}></Route>
            <Route
              path="Privacy & Cookies"
              element={<PrivacyCookies />}
            ></Route>
            <Route
              path="Terms & Conditions"
              element={<TermsConditions />}
            ></Route>
            <Route path="Feedback" element={<Feedback />}></Route>
            <Route
              path="media/Conference Detail"
              element={<ConferenceDetail />}
            ></Route>
            <Route
              path="media/Conference 2024"
              element={<Conference2024 />}
            ></Route>
           
            <Route path="/conference/:postId" element={<SubTheme />}></Route>
          </Route>{" "}
        </>
        <>
          {/* Layout2 */}
          <Route path="/" element={<Layout2 />}>
            <Route path="about us/Our Story" element={<OurStory />}></Route>
            <Route
              path="about us/Where We Work"
              element={<WhereWeWork />}
            ></Route>
            <Route path="about us/Our Values" element={<OurValues />}></Route>
            <Route path="about us/Our Team" element={<OurTeam />}></Route>
            <Route
              path="about us/board members & editorial team"
              element={<BoardMembersEditorialTeam />}
            ></Route>
            <Route
              path="about us/Strategic Plan 2024"
              element={<StrategicPlan />}
            ></Route>
            <Route
              path="programs/Democracy & Political Parties"
              element={<PoliticalPartiesLiason />}
            ></Route>
            {/* Media */}
            <Route
              path="media/Press Releases"
              element={<PressReleases />}
            ></Route>
            <Route path="media/Blog" element={<Blog />}></Route>

            <Route path="media/Gallery" element={<Gallery />}></Route>

            <Route path="resources/downloads" element={<Downloads />}></Route>
            <Route path="resources/Toolkits" element={<ToolKits />}></Route>
            <Route
              path="resources/General Reports"
              element={<GeneralReports />}
            ></Route>
            <Route path="media/Gallery" element={<Gallery />}></Route>
            <Route
              path="conference registration/thankyou"
              element={<ThankYou />}
            ></Route>
             <Route
              path="Thank You"
              element={<ThankYou />}
            ></Route>
            <Route path="*" element={<Nomatch />} />
          </Route>
        </>
        <>
          {/* Layout3 */}
          <Route path="/" element={<Layout3 />}>
            <Route path="journal/journal" element={<JournalPapers />}></Route>
            <Route
              path="journal/Research Calls"
              element={<ReserachCalls />}
            ></Route>
            <Route
              path="journal/Methodologies"
              element={<Methodologies />}
            ></Route>
            <Route path="our projects" element={<Projects />}></Route>
            <Route
              path="conference registration"
              element={<ConferenceRegistration />}
            ></Route>
            <Route
              path="contact/thankyou"
              element={<ThankYouContact />}
            ></Route>
          </Route>
        </>
      </Routes>
    </>
  );
}

export default AppRouter;
